import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import ReactDOM from 'react-dom';

import { Navbar, Homepage, Gallery, Authors, Chapters, Excerpt, Crossroadshome, Crossroadreviews, Crossroadsexcerpt, Crossroadsauthors, Crossroadsgallery, Buy, Blog, Reviews, Nakisanzevideo, Sophielooksback, Chrisconte, Visitwithhilda, Crossroadsblhighlights, Tsitsi, Lydia, Crossroadschapters, Crossroadsbuy, Crossroadsblog, Karamoja, Thebridessong, Ednasjourney, Booklaunchhighlights, Afia, Afiaconte, Afianamara, Afiapocitti, Afiaorando, Afiassenkaaba, Afiaasinde, Afiaariba, Bookpromo, Patriarch, Buwembo,  Rotary} from './components/'

const useStyles = makeStyles((theme) => ({
    app: {
        
    }
}))

const App = () => {

    
const classes = useStyles();
const [crossroads, setCrossroads] =useState(false)

    return (
    <div className="app" style={{backgroundColor: '#b68f72',  }}> 
        <Router>
            
                <Navbar crossroads={crossroads} setCrossroads={setCrossroads}/>
                
                    <Routes>
                        <Route path="/" element={<Homepage crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path ="/buy" element={<Buy crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path="/authors" element={ <Authors crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path="/chapters" element={ <Chapters crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path="/blog" element={ <Blog crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path="/gallery" element={ <Gallery crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path="/reviews" element={ <Reviews crossroads={crossroads} setCrossroads={setCrossroads} /> } />
                        <Route path="/excerpt" element={ <Excerpt crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path="/crossroadshome" element={ <Crossroadshome  crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path="/crossroadsreviews" element={<Crossroadreviews crossroads={crossroads} setCrossroads={setCrossroads} /> } />
                        <Route path ="/crossroadsexcerpt" element={<Crossroadsexcerpt crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path ="/crossroadsauthors" element={<Crossroadsauthors crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path ="/crossroadsgallery" element={<Crossroadsgallery crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path = "/nakisanzevideo" element ={ <Nakisanzevideo crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path = "/sophielooksback" element ={ <Sophielooksback crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path = "/chrisconte" element={ <Chrisconte crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path = "/visitwithhilda" element={ <Visitwithhilda crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path = "/crossroadsblhighlights" element={ <Crossroadsblhighlights crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path = "/tsitsi" element={ <Tsitsi crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path = "/lydia" element={ <Lydia crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path = "/crossroadschapters" element={ <Crossroadschapters crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path = "/crossroadsbuy" element={ <Crossroadsbuy crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path= "/crossroadsblog" element={<Crossroadsblog crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path= "/karamoja" element={<Karamoja crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/thebridessong" element={<Thebridessong crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/ednasjourney" element={<Ednasjourney crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/afia" element={<Afia crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/booklaunchhighlights" element={<Booklaunchhighlights crossroads={crossroads} setCrossroads={setCrossroads}/> } /> 
                        <Route path= "/afiaconte" element={<Afiaconte crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/afianamara" element={<Afianamara crossroads={crossroads} setCrossroads={setCrossroads}/> } /> 
                        <Route path= "/afiapocitti" element={<Afiapocitti crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/afiaorando" element={<Afiaorando crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/afiassenkaaba" element={<Afiassenkaaba crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/afiaasinde" element={<Afiaasinde crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/afiaariba" element={<Afiaariba crossroads={crossroads} setCrossroads={setCrossroads}/> } />
                        <Route path= "/bookpromo" element={ <Bookpromo crossroads={crossroads} setCrossroads={setCrossroads}/>} />  
                        <Route path= "/avisitwithapatriarch" element={ <Patriarch crossroads={crossroads} setCrossroads={setCrossroads}/>} />  
                        <Route path= "/buwembo" element={ <Buwembo crossroads={crossroads} setCrossroads={setCrossroads}/>} />
                        <Route path= "/rotaryclub" element={ <Rotary crossroads={crossroads} setCrossroads={setCrossroads}/>} />    

                    </Routes>
                
            
            
        </Router>
    </div>
    )
}

ReactDOM.render(<App />, document.getElementById('app'))