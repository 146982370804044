import * as React from 'react';
import { Link as Link1 } from 'react-router-dom'
import grandma from '../pics/grandma.png'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'




import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  MenuIcon,
  Avatar,
  Tooltip,
} from '../MUI/index.js'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import NewReleasesIcon from '@mui/icons-material/NewReleases';

const useStyles = makeStyles((theme) => ({
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 },
},

  mobileNav: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    }

  },
  toolBar: {
    [theme.breakpoints.down('md')]: {
      marginRight: '8vh'
    },
    backgroundColor: '#5a2a20',
  },
  buttonBox: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    display: 'flex',
    alignItems:'center'
  },
  popup: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    "& .MuiPaper-root": {
      backgroundColor: '#b68f72'
    }
  },
  popup2: {
    "& .MuiPaper-root": {
      backgroundColor: '#b68f72'
    }
  },
  popup3: {
    "& .MuiPaper-root": {
      backgroundColor: '#b68f72'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#5a2a20",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
    },
  },
  hamburgerMenu: {
    [theme.breakpoints.down('md')]: {
      transform: 'scale(1.5)'
    },
  },
  home: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '3vw'
    },
    my: 1,
    color: 'white',
    // display: 'flex',
    // flexDirection: 'column',
    fontWeight: 'bold',
    fontSize: '1.5vmin',
    textDecoration: 'none'
  },
  downArrow: {
    fontColor: 'white'
  },
  returnText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.5vw',
      marginRight: '10px'
    },
  },
  whatsNew:{
    display: 'flex'
  },
  icon: {
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    color: "#b68f72",
    width: "20%",
    height: "20%",
    position: "absolute",
    right: "-10%",
    top: "-6%",
  },
  icon2: {
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    color :'#5a2a20',
    position: 'relative',
    right: "8px",
    top: "-11px"
  },
  

}))

const Navbar = ({ crossroads, setCrossroads }) => {


  const classes = useStyles();

  const [toggleMenu, setToggleMenu] = useState(false)
  const [toggleMob, setToggleMob] = useState(true)

  //MENU TWO CODE

  const [toggleMenuTwo, setToggleMenuTwo] = useState(true)
  const [toggleMobTwo, setToggleMobTwo] = useState(true)

  //MENU TWO CODE ENDS HERE

  const [anchorE, setAnchorE] = React.useState(null);
  const openMenu = Boolean(anchorE);
  const handleSelect = (event) => {
    setAnchorE(event.currentTarget)
    setToggleMenu(true)
    setToggleMob(false)
  }
  const handleCloseSelect = () => {
    setAnchorE(null);
    setToggleMenu(false)
    setToggleMob(true)
    handleClose()
  };
 
  //Menu 2 Dropdown code//

  const [anchorETwo, setAnchorETwo] = React.useState(null);
  const openMenuTwo = Boolean(anchorETwo);
  const handleSelectTwo = (event) => {
    setAnchorETwo(event.currentTarget)
    setToggleMenuTwo(false)
    setToggleMobTwo(false)
  }
  const handleCloseSelectTwo = () => {
    setAnchorETwo(null);
    setToggleMenuTwo(false)
    setToggleMobTwo(false)
    handleClose()
    // handleCloseTwo()
  };

  //MENU TWO DROPDOWN CODE ENDS HERE


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //MENU TWO CODE//

  // const [anchorElTwo, setAnchorElTwo] = React.useState(null);
  // const openTwo = Boolean(anchorElTwo);
  
  // const handleCloseTwo = () => {
  //   setAnchorElTwo(null);
  // };

  //MENU TWO CODE ENDS HERE

  return (
    <AppBar position="static" className={classes.appBar} sx={{position: 'sticky', top: '0', zIndex: '1'}}id='nav'>

      <div className={classes.container}>

        <Toolbar className={classes.toolBar} disableGutters sx={{ display: {  justifyContent: 'center' } }}>

          <div className={classes.buttonBox}>
            {!crossroads ?
              <>
                <Link1 to="/buy" style={{ textDecoration: 'none' }}>
                  <Button className={classes.button}
                    sx={{
                       my: 2, color: 'white', display: 'block', marginRight: '20px','@media screen and (min-width: 40em)': {
                        marginRight: '10px'}, fontWeight: 'bold', fontSize: '1.5vmin'}}
                  >
                    Buy
                  </Button>
                </Link1>
                <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/authors" style={{ textDecoration: 'none' }}>
                  <Button
                  sx={{
                    my: 2, color: 'white', display: 'block', marginRight: '20px','@media screen and (min-width: 40em)': {
                     marginRight: '10px'}, fontWeight: 'bold', fontSize: '1.5vmin'}}
               >
                    Authors
                  </Button>
                </Link1>
                <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/chapters" style={{ textDecoration: 'none' }}>
                  <Button
                  sx={{
                    my: 2, color: 'white', display: 'block', marginRight: '20px','@media screen and (min-width: 40em)': {
                    marginRight: '10px'}, fontWeight: 'bold', fontSize: '1.5vmin'}}
                  >
                    Chapters
                  </Button>
                </Link1>
                <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/blog" style={{ textDecoration: 'none' }}>
                  <Button
                  sx={{
                    my: 2, color: 'white', display: 'block', marginRight: '20px','@media screen and (min-width: 40em)': {
                    marginRight: '10px'}, fontWeight: 'bold', fontSize: '1.5vmin'}}
                  >
                    Blog
                  </Button>
                </Link1>
                <Button
                  sx={{ my: 2, color: 'white', display: 'flex', marginRight: '5px', fontWeight: 'bold', fontSize: '1.5vmin' }}
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleSelect}
                >Gallery{toggleMenu ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </Button>
                <Menu
                  className={classes.popup}
                  id="basic-menu"
                  anchorEl={anchorE}
                  open={openMenu}
                  onClose={handleCloseSelect}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/rotaryclub" style={{ textDecoration: 'none' }}> <MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>OUR WRITERS HIT THE ROTARY CIRCUIT (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/buwembo" style={{ textDecoration: 'none' }}> <MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>JOACHIM BUWEMBO REMEMBERS THE FUTURE (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/avisitwithapatriarch" style={{ textDecoration: 'none' }}> <MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>A VISIT WITH A PATRIARCH (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/karamoja' style={{ textDecoration: 'none' }}><MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>THIS IS KARAMOJA (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/ednasjourney' style={{ textDecoration: 'none' }}><MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>EDNA NAMARA'S JOURNEY HOME (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/thebridessong' style={{ textDecoration: 'none' }}><MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>THE BRIDE'S SONG (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/afia' style={{ textDecoration: 'none' }}><MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>AFIA PODCAST INTERVIEWS (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/booklaunchhighlights" style={{ textDecoration: 'none' }}> <MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>GLIMPSES OF THE LAUNCH (photos)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/gallery" style={{ textDecoration: 'none' }}> <MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>PHOTO GALLERY</MenuItem></Link1>
                </Menu>
                <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/reviews" style={{ textDecoration: 'none' }}>
                  <Button
                  sx={{
                    my: 2, color: 'white', display: 'block', marginRight: '20px','@media screen and (min-width: 40em)': {
                    marginRight: '10px'}, fontWeight: 'bold', fontSize: '1.5vmin'}}
                  >
                    Reviews
                  </Button>
                </Link1>
                <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/excerpt" style={{ textDecoration: 'none' }}>
                  <Button
                  sx={{
                    my: 2, color: 'white', display: 'block', marginRight: '20px','@media screen and (min-width: 40em)': {
                    marginRight: '10px'}, fontWeight: 'bold', fontSize: '1.5vmin'}}
                  >
                    Excerpt
                  </Button>
                </Link1>
                
                {/* WHATS NEW DROP DOWN */}

                <Button
                  sx={{ my: 2, color: 'white', display: 'flex', marginRight: '5px', fontWeight: 'bold', fontSize: '1.5vmin' }}
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleSelectTwo}
                // >WHAT'S NEW{toggleMenuTwo ? <ArrowDropDownIcon /> : <ArrowRightIcon />} 
                ><div className={classes.whatsNew}>WHAT'S NEW{ toggleMenuTwo ? <NewReleasesIcon className={classes.icon}></NewReleasesIcon> : null}</div>
                </Button>
                <Menu
                  className={classes.popup}
                  id="basic-menu"
                  anchorEl={anchorETwo}
                  open={openMenuTwo}
                  onClose={handleCloseSelectTwo}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/rotaryclub' style={{ textDecoration: 'none' }}><MenuItem onClick={handleCloseSelectTwo} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>OUR WRITERS HIT THE ROTARY CIRCUIT (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/buwembo' style={{ textDecoration: 'none' }}><MenuItem onClick={handleCloseSelectTwo} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>AN INTERVIEW WITH JOACHIM BUWEMBO (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/avisitwithapatriarch' style={{ textDecoration: 'none' }}><MenuItem onClick={handleCloseSelectTwo} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>A VISIT WITH A PATRIARCH (video)</MenuItem></Link1>
                  <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/bookpromo' style={{ textDecoration: 'none' }}><MenuItem onClick={handleCloseSelectTwo} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>NEW: BUY THE BOOK AND GET "CROSSROADS" FREE!</MenuItem></Link1>
                </Menu>

                {/* WHATS NEW MENU ENDS HERE */}

              </>
              : null
            }

          </div>
          {!crossroads ?
            <>
              <Box className={classes.mobileNav} sx={{ color: 'black' }}>
                <div>
                  <Button sx={{ color: 'White', horizontal: 'left' }}
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    < MenuIcon className={classes.hamburgerMenu} />
                  </Button>
                  <Menu
                    className={classes.popup2}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}

                  >
                    <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/buy' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleClose}>BUY</MenuItem></Link1>
                    <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/authors' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleClose}>AUTHORS</MenuItem></Link1>
                    <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/chapters' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleClose}>CHAPTERS</MenuItem></Link1>
                    <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/blog' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleClose}>BLOG</MenuItem></Link1>
                    <MenuItem
                      sx={{ color: 'white' }}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleSelect}>
                      GALLERY{toggleMob ? <ArrowDropDownIcon className={classes.downArrow} /> : <ArrowRightIcon className={classes.rightArrow} />}
                    </MenuItem>
                    <Menu
                      className={classes.popup3}
                      id="basic-menu"
                      anchorEl={anchorE}
                      open={openMenu}
                      onClose={handleCloseSelect}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left'
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/rotaryclub' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect} style={{ color: 'White', textDecoration: 'none' }}>OUR WRITERS HIT THE ROTARY CIRCUIT (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/buwembo' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect} style={{ color: 'White', textDecoration: 'none' }}>JOACHIM BUWEMBO REMEMBERS THE FUTURE (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/avisitwithapatriarch' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect} style={{ color: 'White', textDecoration: 'none' }}>A VISIT WITH A PATRIARCH (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/karamoja' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect} style={{ color: 'White', textDecoration: 'none' }}>THIS IS KARAMOJA (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/ednasjourney' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect} style={{ color: 'White', textDecoration: 'none' }}>EDNA NAMARA'S JOURNEY HOME (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/thebridessong' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect} style={{ color: 'White', textDecoration: 'none' }}>THE BRIDE'S SONG (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/afia' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect} style={{ color: 'White', textDecoration: 'none' }}>AFIA PODCAST INTERVIEWS (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/booklaunchhighlights" style={{ textDecoration: 'none' }}> <MenuItem onClick={handleCloseSelect} sx={{ backgroundColor: '#b68f72', color: 'White', textDecoration: 'none' }}>GLIMPSES OF THE LAUNCH (photos)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/gallery' style={{ color: 'white', textDecoration: 'none' }} > <MenuItem onClick={handleCloseSelect}>PHOTO GALLERY</MenuItem></Link1>
                    </Menu>
                    <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/reviews' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleClose}>REVIEWS</MenuItem></Link1>
                    <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/excerpt' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleClose}>EXCERPT</MenuItem></Link1>
                    <MenuItem
                      sx={{ color: 'white' }}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleSelectTwo}>
                      WHAT'S NEW{ toggleMobTwo ? <NewReleasesIcon className={classes.icon2}></NewReleasesIcon> : null}
                    </MenuItem>
                    <Menu
                      className={classes.popup3}
                      id="basic-menu"
                      anchorEl={anchorETwo}
                      open={openMenuTwo}
                      onClose={handleCloseSelectTwo}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left'
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/rotaryclub' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelectTwo} style={{ color: 'White', textDecoration: 'none' }}>OUR WRITERS HIT THE ROTARY CIRCUIT (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/buwembo' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelectTwo} style={{ color: 'White', textDecoration: 'none' }}>AN INTERVIEW WITH JOACHIM BUWEMBO (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/avisitwithapatriarch' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelectTwo} style={{ color: 'White', textDecoration: 'none' }}>A VISIT WITH A PATRIARCH (video)</MenuItem></Link1>
                      <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/bookpromo' style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelectTwo} style={{ color: 'White', textDecoration: 'none' }}>NEW: BUY NOW AND GET "CROSSROADS" FREE!</MenuItem></Link1>
                    </Menu>

                  </Menu>
                </div>
              </Box>
            </>
            : null
          }

        </Toolbar>

        <Box sx={{ flexGrow: 1, position: 'absolute', right: 0, marginRight: '1%', display: 'flex', flexDirection: 'row', alignItems: 'center', }} className={classes.homeIcon}>
          {crossroads ? <><Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/' style={{ textDecoration: 'none' }}><Button

            sx={{ my: 2, color: 'white', display: 'block', fontSize: '1.5vh' }} onClick={function () { setCrossroads(false) }}
          >
            <div className={classes.returnText}>Back to Remembering the Future</div>
          </Button></Link1></> : <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to='/' style={{ color: 'white', textDecoration: 'none' }}><Button to="/"
          >
            <div className={classes.home}>Home</div>
          </Button></Link1>
          }
          <Tooltip title="Home">
            <Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/" style={{ textDecoration: 'none' }} >
              <IconButton sx={{  right: '0' }} onClick={function () { setCrossroads(false) }}>
                <Avatar alt="Home" src={grandma} />
              </IconButton>
            </Link1>
          </Tooltip>
        </Box>
      </div>

    </AppBar>

  );
};
export default Navbar;